import { gql } from '@apollo/client'


const ADD_TO_CART_MUTATION = gql`
  mutation ATC($input: AddToCartInput!) {
    addToCart(input: $input) {
      cart {
        subtotal
        total
        shippingTotal
        contents {
          itemCount
          nodes {
            key
            quantity
            subtotal
            product {
              node {
                name
                databaseId
                image {
                  sourceUrl
                }
                ... on SimpleProduct {
                  price
                  salePrice
                  regularPrice
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ADD_TO_CART_MUTATION
