import { useMutation } from '@apollo/client'
import { Button, useToast } from '@chakra-ui/react'
import React from 'react'
import ADD_TO_CART from '../mutations/AddToCart'
import { useCartState } from './CartContext'

const AddToCartButton: React.FC<{ productId: number; quantity: number }> = ({
  productId,
  quantity,
}) => {
  const toast = useToast()
  const { setCart } = useCartState()

  const [addToCart, { loading }] = useMutation(ADD_TO_CART, {
    onCompleted: ({ addToCart }) => {
      toast({
        title: 'Added to cart',
        status: 'success',
      })
      setCart(addToCart.cart)
    },
    onError: () => {
      toast({
        title: 'Error',
        description: 'There was an error adding your product',
        status: 'error',
      })
    },
  })

  function handleAddToCart() {
    addToCart({
      variables: {
        input: { productId, quantity, clientMutationId: 'EDD_ADD_CART' },
      },
    })
  }

  return (
    <Button
      onClick={() => handleAddToCart()}
      isLoading={loading}
      loadingText='Adding to cart'
      px={2}
      py={1}
      bg='#d32f2f'
      fontSize={{ base: 'md', md: 'lg' }}
      color='white'
      fontWeight='bold'
      rounded='lg'
      textTransform='uppercase'
      _hover={{
        bg: 'gray.200',
        color: 'black',
      }}
      _focus={{
        bg: 'gray.400',
        color: 'black',
      }}
    >
      Add To Cart
    </Button>
  )
}

export default AddToCartButton
