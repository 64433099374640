import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/common/Layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import AddToCartButton from '../components/context/AddToCartButton'
import {
  VStack,
  HStack,
  Button,
  Container,
  Box,
  Heading,
  Text,
  Stack,
} from '@chakra-ui/react'

/* const productDetailBoxStyle = {
  background: 'rgba(246, 246, 246, 0.5)',
  borderRadius: '16px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(5px)',
  WebkitBackdropFilter: 'blur(5px)',
  border: '1px solid rgba(246, 246, 246, 0.3)',
  padding: '15px',
  margin: '15px 0px 15px 0px',
  width: '100%',
} */

function ButtonIncrement(props) {
  return (
    <Button
      w='25px'
      ml='5'
      bgColor='#d32f2f'
      fontSize='20px'
      color='white'
      mr='10'
      _hover={{
        bg: 'gray.200',
        color: 'black',
      }}
      onClick={props.onClickFunc}
    >
      +
    </Button>
  )
}
function ButtonDecrement(props) {
  return (
    <Button
      w='25px'
      ml='5'
      bgColor='#d32f2f'
      fontSize='20px'
      color='white'
      _hover={{
        bg: 'gray.200',
        color: 'black',
      }}
      onClick={props.onClickFunc}
    >
      -
    </Button>
  )
}
function Display(props) {
  return (
    <Text as='label' ml='2' mr='2' fontSize='22' color='black'>
      {props.message}
    </Text>
  )
}

const ProductPageTemplate = ({ data }) => {
  const [counter, setCounter] = useState(1)
  const incrementCounter = () => setCounter(counter + 1)
  let decrementCounter = () => setCounter(counter - 1)
  if (counter <= 1) {
    decrementCounter = () => setCounter(1)
  }

  //console.log({data})
  const product = data.wpProduct

  let price
  if (product.salePrice !== null) {
    price = (
      <Heading fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}>
        <del>{product.regularPrice}</del>&nbsp;&nbsp;{product.salePrice}
      </Heading>
    )
  } else {
    price = (
      <Heading fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}>
        £{product.salePrice}
      </Heading>
    )
  }

  const image = getImage(product.image.localFile.childImageSharp)

  //console.log(bg)

  return (
    <Layout>
      <Container
        bgColor='transparent'
        w='full'
        centerContent
        maxW='container.2xl'
        mt='50px'
      >
        <Stack
          direction={['column', 'row']}
          bgColor='rgba(246, 246, 246, 0.3)'
          shadow='2xl'
          backgroundBlendMode='multiply'
          boxShadow='0 0 5px #ccc'
          filter='auto'
          //blur='3px'
          w={['full', '90%']}
          borderRadius='10px'
          p='10'
          spacing={['10', '40']}
          alignItems='center'
        >
          {image ? (
            <GatsbyImage
              image={image}
              alt={product.name}
              objectFit={'scale-down'}
            />
          ) : null}

          <VStack w='full' m='auto' align='top' spacing={['0', '30']}>
            <Heading as='h1' size='xl' fontWeight='bold' color='#3d4574'>
              {product.name}
            </Heading>
            <Box>
              <h3>{price}</h3>
            </Box>
            <Stack direction={['column', 'row']}>
              <HStack fontSize={['20px', '50px']} align='center'>
                <ButtonDecrement onClickFunc={decrementCounter} />
                <Display message={counter} />
                <ButtonIncrement onClickFunc={incrementCounter} />
              </HStack>
              <AddToCartButton
                productId={product.databaseId}
                quantity={counter}
              />
            </Stack>

            {product.shortDescription ? (
              <Text
                color='#3d4574'
                dangerouslySetInnerHTML={{
                  __html: `${product.shortDescription}`,
                }}
              />
            ) : null}
          </VStack>
        </Stack>
        {product.description ? (
          <Box
            bgColor='rgba(246, 246, 246, 0.3)'
            shadow='2xl'
            backgroundBlendMode='multiply'
            boxShadow='0 0 5px #ccc'
            filter='auto'
            //blur='3px'
            w={['full', '90%']}
            borderRadius='10px'
            p={['2', '20']}
          >
            <Text
              color='#3d4574'
              dangerouslySetInnerHTML={{
                __html: `${product.description}`,
              }}
            />
          </Box>
        ) : null}
      </Container>
    </Layout>
  )
}

export default ProductPageTemplate

export const query = graphql`
  query ($id: String!) {
    wpProduct(id: { eq: $id }) {
      name
      id
      databaseId
      ... on WpSimpleProduct {
        regularPrice
        salePrice
      }
      slug
      description
      shortDescription
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              height: 600
              width: 600
              webpOptions: { quality: 100 }
              formats: WEBP
            )
          }
        }
      }
    }
  }
`
